import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useSessionStorage } from "usehooks-ts";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

function AddSurvey() {
  const api = process.env.REACT_APP_DATABASE_URI;
  const navigate = useNavigate();
  const [authcode, setAuthCode, removeAuthCode] = useSessionStorage(
    "authToken",
    ""
  );

  const [show, setShow] = useState(false);
  const [selectedPositions, setSelectedPositions] = useState([]);

  // Positions array
  const positions = ["TM", "TL", "LCVP", "LCP", "MCVP", "MCP"];

  // Toggle function for selecting/deselecting positions
  const togglePosition = (position) => {
    if (selectedPositions.includes(position)) {
      setSelectedPositions(
        selectedPositions.filter((item) => item !== position)
      );
    } else {
      setSelectedPositions([...selectedPositions, position]);
    }
  };
  const handleClose = () => {
    setShow(false);
    navigate("/mx-surveys");
  };
  const handleCloseAndAdd = () => {
    setShow(false);
    setSurvey({
      title: "",
      scope: "local",
      deadline: "",
      positions: [],
      survey_url: "",
      description: "",
    });
  };

  //Add Survey POST
  const addSurvey = useMutation({
    mutationFn: (body) => {
      return fetch(`${api}/surveys`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
        body: JSON.stringify(body), // Convert body object to JSON string
      });
    },
    onSuccess: (data) => {
      console.log("Survey created successfully", data);

      setShow(true);
    },
    onError: (error) => {
      navigate("/redirect?code=refresh&return_url=/mx-surveys/add");
      return <h3>Error fetching</h3>;
    },
  });
  //Survey State Init
  const [survey, setSurvey] = useState({
    title: "",
    scope: "local",
    deadline: "",
    positions: [],
    survey_url: "",
    description: "",
  });

  //Hande state changes for Survey
  const handleSurveyChange = (event) => {
    const { name, value } = event.target;
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      [name]: value,
    }));
  };

  //Handle the submission of the Form
  const handleSubmit = (e) => {
    e.preventDefault();
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      positions: selectedPositions,
    }));
    addSurvey.mutate(survey);
  };

  //Page Body
  return (
    <div className="addSurv">
      <h1 className="addTitle">Add Survey</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Enter title"
                value={survey.title}
                onChange={handleSurveyChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formScope">
              <Form.Label>Scope</Form.Label>
              <Form.Control
                as="select"
                name="scope"
                value={survey.scope}
                onChange={handleSurveyChange}
              >
                <option value="local">Local</option>
                <option value="national">National</option>
                <option value="international">International</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formDeadline">
              <Form.Label>Application Deadline</Form.Label>
              <Form.Control
                type="date"
                name="deadline"
                value={survey.deadline}
                onChange={handleSurveyChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="posSelect" controlId="formDeadline">
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  Select Positions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {positions.map((position, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => togglePosition(position)}
                      active={selectedPositions.includes(position)}
                    >
                      {position}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <strong>Selected Positions:</strong>{" "}
              {selectedPositions.join(", ")}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formAssessmentForm">
              <Form.Label>Survey Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                placeholder="Enter survey Description"
                value={survey.description}
                onChange={handleSurveyChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formAssessmentForm">
              <Form.Label>Survey Form URL</Form.Label>
              <Form.Control
                type="url"
                name="survey_url"
                placeholder="Enter survey form URL"
                value={survey.survey_url}
                onChange={handleSurveyChange}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Button size="lg" variant="primary" type="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Survey added successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseAndAdd}>
            Add another
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default AddSurvey;
