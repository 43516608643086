import { Row, Col, Form, InputGroup, Button, Table } from "react-bootstrap";
import { Pencil, PeopleFill, PersonFill, Search } from "react-bootstrap-icons";
import "../../Assets/Scss/Opportunities.scss";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { useQuery } from "@tanstack/react-query";
import { useSessionStorage } from "usehooks-ts";
import dayjs from "dayjs";

function OpportunitiesAssesmentList() {
  const api = process.env.REACT_APP_DATABASE_URI;
  const [authcode, ,] = useSessionStorage("authToken", "");
  const listcount = 16;
  const navigate = useNavigate();
  const {
    isPending,
    isError,
    data: opportunities,
    error,
  } = useQuery({
    queryKey: ["opportunities"],
    retry: false,
    queryFn: () =>
      fetch(`${api}/opportunities/live`, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok ", error);
        }
        return res.json();
      }),
  });

  if (isPending) {
    return (
      <TailSpin
        visible={true}
        height="150"
        width="150"
        color="#037EF2"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperClass="spinner"
      />
    );
  }

  if (isError) {
    navigate("/redirect?code=refresh&return_url=/opportunities/assesments");
    return <h3>Error fetching</h3>;
  }

  const clickRow = (oppId) => {
    navigate("/opportunities/" + oppId);
  };

  const clickRowEdit = (oppId) => {
    navigate("/opportunities/manage/" + oppId);
  };
  return (
    <div className="oppList">
      <Row>
        <Form>
          <Row>
            <Col lg={7}>
              <InputGroup size="lg" className="mb-4">
                <InputGroup.Text id="basic-addon1">
                  <Search color="#037EF2" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search here" />
              </InputGroup>
            </Col>
            <Col>
              <Form.Select size="lg" aria-label="Default select example">
                <option value="1">Newest</option>
                <option value="2">Oldest</option>
                <option value="3">Most Applied</option>
              </Form.Select>
            </Col>
          </Row>
        </Form>
      </Row>
      <Table className="OppsTable">
        <thead>
          <tr>
            <th>Type</th>
            <th>Opportunity Name</th>
            <th>Opportunity Scope</th>
            <th>Application Launch</th>
            <th>Application Deadline</th>
            <th>Assessment Form Link</th>
          </tr>
        </thead>
        <tbody>
          {opportunities.map((ele, index) => {
            return (
              <tr
                style={{ cursor: "pointer" }}
                key={ele.opportunity_id}
                onClick={() => clickRow(ele.opportunity_id)}
              >
                <td>
                  {ele.type === "one" ? (
                    <PersonFill color="#E0A900" size={32} />
                  ) : (
                    <PeopleFill color="#E0A900" size={32} />
                  )}
                </td>
                <td>{ele.title}</td>
                <td style={{ textTransform: "capitalize" }}>{ele.scope}</td>
                <td className="grayCol">
                  {dayjs(ele.creation_date).format("YYYY-MM-DD")}
                </td>
                <td className="grayCol">
                  {dayjs(ele.deadline).format("YYYY-MM-DD")}
                </td>
                <td className="grayCol">
                  <a href={ele.assessment_form}>{ele.assessment_form}</a>
                </td>
              </tr>
            );
          })}

          {[...Array(listcount)].map((obj, index) => {
            return (
              <tr key={`key:${index}`}>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td className="oppProv">&nbsp;</td>
                <td className="grayCol">&nbsp;</td>
                <td className="grayCol">&nbsp;</td>
                <td className="grayCol">&nbsp;</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
export default OpportunitiesAssesmentList;
