import OppInfo from "./OppInfo";
import "../../Assets/Scss/Opportunities.scss";
import { Button, Form, Modal } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSessionStorage } from "usehooks-ts";
import { TailSpin } from "react-loader-spinner";
import { useState } from "react";
import { Store } from "react-notifications-component";

function PositionDetails() {
  const api = process.env.REACT_APP_DATABASE_URI;
  const pathname = window.location.pathname;
  const posId = pathname.split("/")[3];
  const oppId = pathname.split("/")[2];

  const [application, setApplication] = useState({
    position_id: posId,
    application_url: "",
  });

  const [show, setShow] = useState(false);

  const handleClose = () => {
    apply.mutate(application);
  };

  const [authcode, ,] = useSessionStorage("authToken", "");

  //Opportunity info pulling
  const { isPending: isPendingOpp, data: opportunity } = useQuery({
    queryKey: ["applyPos", posId],
    retry: false,
    queryFn: () =>
      fetch(`${api}/opportunities/` + oppId, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok ", error);
        }
        return res.json();
      }),
  });

  const handleApplicationChange = (e) => {
    const { name, value } = e.target;
    setApplication((prevApplication) => ({
      ...prevApplication, // Keep other fields in the state
      [name]: value, // Update only the targeted field
    }));
  };

  const apply = useMutation({
    mutationFn: (body) => {
      return fetch(`${api}/applications`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
        body: JSON.stringify(body), // Convert body object to JSON string
      });
    },
    onSuccess: (data) => {
      console.log("Applied successfully", data);
      Store.addNotification({
        message: "Application sent successfully!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      setShow(false);
    },
    onError: (error) => {
      console.error("Error applying:", error.message);
    },
  });

  const { isPending, data, error } = useQuery({
    queryKey: ["position"],
    retry: false,
    queryFn: () =>
      fetch(`${api}/opportunities/positions/` + posId, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok ", error);
        }
        return res.json();
      }),
  });

  if (isPending || isPendingOpp) {
    return (
      <TailSpin
        visible={true}
        height="150"
        width="150"
        color="#037EF2"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperClass="spinner"
      />
    );
  }

  return (
    <div className="positionDetails">
      <OppInfo posTitle={data.title} data={opportunity} />
      <div className="textBlock">
        <h1>Job Description</h1>
        <p>{data.job_description}</p>
      </div>
      <div className="textBlock">
        <h1>Requirements</h1>
        <ul>
          {data.requirements_description.split(/\r?\n/).map((ele) => {
            return <li key={ele}>{ele}</li>;
          })}
        </ul>
      </div>
      <div className="textBlock">
        <h1>Benefits</h1>
        <ul>
          {data.benefits_description.split(/\r?\n/).map((ele) => {
            return <li key={ele}>{ele}</li>;
          })}
        </ul>
      </div>
      <Button className="applyButton" onClick={() => setShow(true)}>
        Apply Now
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apply for Position</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formTitle">
            <Form.Label>Application URL</Form.Label>
            <Form.Control
              type="url"
              name="application_url" // Match this name with the state field
              value={application.application_url}
              onChange={handleApplicationChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="success" onClick={handleClose}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default PositionDetails;
