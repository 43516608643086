import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";
import { useQuery } from "@tanstack/react-query";
import { TailSpin } from "react-loader-spinner";

function RedirectHandler(props) {
  const api = process.env.REACT_APP_DATABASE_URI;
  const navigate = useNavigate();
  const [authcode, setAuthCode, removeAuthCode] = useSessionStorage(
    "authToken",
    ""
  );
  const [refreshcode, setRefreshCode, removeRefreshCode] = useSessionStorage(
    "refreshToken",
    ""
  );
  //Get the access token from the URL after being redirected from Expa's Login page
  const getAccessCode = () => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    return code;
  };
  //Set the refresh and access tokens in the sessions storage
  const setTokens = (data, return_url) => {
    setAuthCode(data.access_token);
    setRefreshCode(data.refresh_token);
    navigate(return_url);
  };
  //Get what type of redirect brought you here:
  //If it's refresh, to refresh the data token and get a new refresh token
  //If it's default, to get the data token from Expa's temporary access token
  const getRedirectType = () => {
    const params = new URLSearchParams(window.location.search);
    const return_url = params.get("return_url");
    const code = params.get("code");
    return { isRefresh: code === "refresh", return_url: return_url };
  };
  //Get the data token from the temporary access token
  const { isPending, isError, data, error, isSuccess } = useQuery({
    queryKey: ["authToken"],
    enabled: !getRedirectType().isRefresh,
    retry: false,
    queryFn: () =>
      fetch(`${api}/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "temporary-access-code": getAccessCode(),
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok ", error);
        }
        return res.json();
      }),
  });
  //Refresh the data token using the refresh token when it has expired
  const {
    isPending: isPendingRef,
    isError: isErrorRef,
    data: dataRef,
    error: errorRef,
    isSuccess: isSuccessRef,
  } = useQuery({
    queryKey: ["refreshToken"],
    enabled: getRedirectType().isRefresh,
    retry: false,
    queryFn: () =>
      fetch(`${api}/auth/refresh`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "refresh-token": refreshcode,
        },
      }).then((res) => {
        if (!res.ok) {
          navigate("/login");
          throw new Error("Network response was not ok ", errorRef);
        }
        return res.json();
      }),
  });

  useEffect(() => {
    props.callBack(false);
  });

  useEffect(() => {
    if (isSuccess) {
      setTokens(data, "/redirectProfile?return_url=/opportunities");
    }
    if (isSuccessRef) {
      setTokens(
        dataRef,
        "/redirectProfile?return_url=" + getRedirectType().return_url
      );
    }
  });

  if (isPending || isPendingRef) {
    return (
      <div className="redirect">
        <TailSpin
          visible={true}
          height="150"
          width="150"
          color="#037EF2"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }
  if (isError || isErrorRef) {
    console.error("Fetch error:", error || errorRef);
  }
}
export default RedirectHandler;
