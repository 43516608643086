import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { ArrowRight, Search } from "react-bootstrap-icons";
import "../../Assets/Scss/Surveys.scss";
import { useQuery } from "@tanstack/react-query";
import { useSessionStorage } from "usehooks-ts";
import { TailSpin } from "react-loader-spinner";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
function SurveyList() {
  const api = process.env.REACT_APP_DATABASE_URI;
  const [authcode, ,] = useSessionStorage("authToken", "");
  const [show, setShow] = useState(false);
  const [modalText, setModalText] = useState("");
  const navigate = useNavigate();

  const showModal = (text) => {
    setModalText(text);
    setShow(true);
  };
  const {
    isPending,
    isError,
    data: surveys,
    error,
  } = useQuery({
    queryKey: ["surveys"],
    retry: false,
    queryFn: () =>
      fetch(`${api}/surveys/my`, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok ", error);
        }
        return res.json();
      }),
  });

  if (isPending) {
    return (
      <TailSpin
        visible={true}
        height="150"
        width="150"
        color="#037EF2"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperClass="spinner"
      />
    );
  }

  if (isError) {
    navigate("/redirect?code=refresh&return_url=/mx-surveys");
    return <h3>Error fetching</h3>;
  }

  return (
    <div className="surveyList">
      <Row className="search">
        <Form>
          <Row>
            <Col lg={8}>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <Search color="#037EF2" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search here" />
              </InputGroup>
            </Col>
            <Col>
              <Form.Select aria-label="Default select example">
                <option value="1">Newest</option>
                <option value="2">Oldest</option>
                <option value="3">Most Applied</option>
              </Form.Select>
            </Col>
            <Col>
              <Button className="addOppBtn" href="/mx-surveys/add">
                Add Survey
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      <Row className="surveyCards">
        {surveys.map((ele, indx) => {
          return (
            <Col lg={4} className="surveyCard" key={indx}>
              <Row>
                <Col>
                  <p className="surveyTitle">{ele.title}</p>
                </Col>
                <Col>
                  <p className="surveyDeadline">
                    {dayjs(ele.deadline).diff(dayjs(), "day")} days left
                  </p>
                </Col>
              </Row>
              <Col className="surveyDesc">
                {console.log(ele.description.length > 150)}
                {ele.description.length <= 150 ? (
                  <p>{ele.description}</p>
                ) : (
                  <p>
                    {ele.description.substring(0, 75)}...{" "}
                    <span
                      onClick={() => showModal(ele.description)}
                      className="readMore"
                    >
                      Read More
                    </span>
                  </p>
                )}
              </Col>
              <Col className="fillNow" lg={{ offset: 8 }}>
                <a href={ele.survey_url}>
                  Fill Now <ArrowRight />
                </a>
              </Col>
            </Col>
          );
        })}
      </Row>
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Survey Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default SurveyList;
