import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

function ProfileInfo(props) {
  const [lc, setLc] = useState("");
  const [position, setPosition] = useState("");
  useEffect(() => {
    setPosition(props.profileData.current_positions[0].current_position_role);
    setLc(props.profileData.current_positions[0].current_position_office);
  }, [props.profileData]);
  return (
    <Row className="profInfo">
      <Col lg={2} md={4}>
        <img src={props.profileData.profile_photo} alt="profile" />
      </Col>
      <Col>
        <h1 className="name">{props.profileData.full_name}</h1>
        <p>
          <a href={`mailto:${props.profileData.aiesec_email}`}>
            {props.profileData.aiesec_email}
          </a>
        </p>
        <p>Local Comittee: {lc}</p>
        <Row className="info">
          <Col>
            <p>Gender: {props.profileData.gender}</p>
          </Col>
          <Col>
            <p>
              Phone Number:{" "}
              {props.profileData.phone ? props.profileData.phone : "Not set"}
            </p>
          </Col>
          <Col>
            <p>
              Date of Birth:{" "}
              {dayjs(props.profileData.date_of_birth).format("DD-MM-YYYY")}
            </p>
          </Col>
        </Row>
      </Col>
      <Col lg={2}>
        <p className="currentPos">{position}</p>
      </Col>
    </Row>
  );
}
export default ProfileInfo;
