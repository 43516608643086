import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { ArrowRight, Search } from "react-bootstrap-icons";
import "../../Assets/Scss/Complaints.scss";
import { useQuery } from "@tanstack/react-query";
import { useSessionStorage } from "usehooks-ts";
import { TailSpin } from "react-loader-spinner";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
function ComplaintList() {
  const api = process.env.REACT_APP_DATABASE_URI;
  const [authcode, ,] = useSessionStorage("authToken", "");
  const navigate = useNavigate();

  const {
    isPending,
    isError,
    data: complaints,
    error,
  } = useQuery({
    queryKey: ["complaints"],
    retry: false,
    queryFn: () =>
      fetch(`${api}/complaints`, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok ", error);
        }
        return res.json();
      }),
  });

  if (isPending) {
    return (
      <TailSpin
        visible={true}
        height="150"
        width="150"
        color="#037EF2"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperClass="spinner"
      />
    );
  }

  if (isError) {
    navigate("/redirect?code=refresh&return_url=/complaints");
    return <h3>Error fetching</h3>;
  }

  return (
    <div className="complaintList">
      <Row className="search">
        <Form>
          <Row>
            <Col lg={8}>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <Search color="#037EF2" />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search here" />
              </InputGroup>
            </Col>
            <Col>
              <Form.Select aria-label="Default select example">
                <option value="1">Newest</option>
                <option value="2">Oldest</option>
                <option value="3">Most Applied</option>
              </Form.Select>
            </Col>
          </Row>
        </Form>
      </Row>
      <Row className="complaintCards">
        {complaints.map((ele, indx) => {
          return (
            <Col lg={4} className="complaintCard" key={indx}>
              <Row>
                <Col>
                  <p className="complaintTitle">{ele.complaint_subject}</p>
                </Col>
              </Row>
              <Col>
                <p className="complaintDesc">{ele.complaint_text}</p>
              </Col>
              <Col className="fillNow">
                Created at {dayjs(ele.creation_date).format("DD/MM/YYYY")}
              </Col>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
export default ComplaintList;
