import "../Assets/Scss/LoginPage.scss";
import wave from "../Assets/Images/wave.gif";
import aiesecA from "../Assets/Images/a.png";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";
function Login(props) {
  const api = process.env.REACT_APP_DATABASE_URI;
  console.log(api);
  useEffect(() => {
    props.callBack(false);
  });
  const isLoggedIn = false;
  const handleAuthRedirect = async () => {
    if (!isLoggedIn) {
      const url = new URL(`https://auth.aiesec.org/oauth/authorize`);
      url.searchParams.set("response_type", "code");
      url.searchParams.set(
        "client_id",
        "_nq8JqcmQ2F6u_Kmds1ZnwcDdB9PTPXKD1B1_fd-mLk"
      );
      url.searchParams.set("redirect_uri", "https://membership.aiesec.net/redirect");
      url.searchParams.set("state", "");

      document.cookie = `redirect_uri=${window.location.href}; HttpOnly; Secure; Path=/`;
      window.location.href = url.toString();
    }
  };
  return (
    <div className="loginPage">
      <div className="welcomeBox">
        <img className="wave" src={wave} alt="handwave.gif" />
        <h2>Welcome to</h2>
        <h1>MX Platform</h1>
        <Button
          onClick={handleAuthRedirect}
          variant="primary"
          className="loginbtn btn-primary"
        >
          Login with Expa{" "}
          <img className="AiesecIcon" src={aiesecA} alt={"AIESEC"} />
        </Button>
      </div>
    </div>
  );
}
export default Login;
