import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "../Assets/Scss/MXSidebar.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { Bell, Gear, Search } from "react-bootstrap-icons";
import { useSessionStorage } from "usehooks-ts";
import aiesecA from "../Assets/Images/a.png";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";

function MXSidebar(props) {
  const api = process.env.REACT_APP_DATABASE_URI;

  const navigate = useNavigate();
  const [authcode, setAuthCode, removeAuthCode] = useSessionStorage(
    "authToken",
    ""
  );
  const [refreshcode, setRefreshCode, removeRefreshCode] = useSessionStorage(
    "refreshToken",
    ""
  );
  const [profileData, setProfileData, removeProfileData] = useSessionStorage(
    "profileData",
    ""
  );
  const [show, setShow] = useState(false);
  const [complaintSubj, setComplaintSubj] = useState("");
  const [complaint, setComplaint] = useState("");
  const handleComplaintChange = (e) => {
    setComplaint(e.target.value);
  };
  const handleComplaintSubjChange = (e) => {
    setComplaintSubj(e.target.value);
  };
  const Logout = () => {
    removeAuthCode();
    removeRefreshCode();
    navigate("/opportunities");
  };

  const addComp = useMutation({
    mutationFn: (body) => {
      return fetch(`${api}/complaints`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
        body: JSON.stringify(body), // Convert body object to JSON string
      });
    },
    onSuccess: (data) => {
      console.log("Complaint created successfully", data);

      setShow(true);
    },
    onError: (error) => {
      navigate("/redirect?code=refresh&return_url=/opportunities");
      return <h3>Error fetching</h3>;
    },
  });

  const SubmitComplaint = () => {
    const complaintBody = {
      complaint_subject: complaintSubj,
      complaint_text: complaint,
    };
    addComp.mutate(complaintBody);
    setShow(false);
    setComplaint("");
    setComplaintSubj("");
  };
  const location = useLocation();
  const { hash, pathname, search } = location;
  return props.viewSideBar ? (
    <div className="overlay">
      <div className="topOverlay">
        <div className="sidebar-title-container">
          <h1 className="sidebar-title">MX Platform</h1>
          <svg
            className="title-icon"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1.36499"
              width="16"
              height="16"
              rx="8"
              stroke="#037EF2"
              strokeWidth="2"
            />
            <rect
              x="7"
              y="7.36499"
              width="4"
              height="4"
              rx="2"
              fill="#FFFFFF"
              stroke="#037EF2"
              strokeWidth="2"
            />
          </svg>
        </div>
        {authcode !== "" && authcode !== null ? (
          <div className="profile">
            <Button className="profile-button">
              <Search size={15} color="#A098AE" />
            </Button>
            <Button className="profile-button">
              <Bell size={15} color="#A098AE" />
            </Button>
            <Dropdown autoClose={false}>
              <Dropdown.Toggle variant="dark" className="profile-button">
                <Gear size={15} color="#A098AE" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  File a complaint
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    Logout();
                  }}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <img
              src={profileData.profile_photo}
              alt="profile"
              className="profilepic"
            />
          </div>
        ) : (
          <Button className="loginBtn" href="/login">
            Login <img className="AiesecIcon" src={aiesecA} alt={"AIESEC"} />
          </Button>
        )}
      </div>
      <div className="content">
        <div style={{ flex: 0.45, backgroundColor: "#037EF2" }}>
          <Sidebar backgroundColor="#037EF2" width="100%">
            <Menu
              menuItemStyles={{
                icon: () => {
                  return {
                    fill: "#FF00000",
                  };
                },
                button: ({ level, active }) => {
                  // only apply styles on first level elements of the tree
                  if (level === 0)
                    return {
                      color: active ? "#037EF2" : "#FFFFFF",
                      padding: "20px 40px",
                      margin: "15px 20px",
                      borderRadius: "6px",
                      "& svg": active
                        ? {
                            fill: "#037EF2",
                          }
                        : {
                            fill: "#FFFFFF",
                          },
                      backgroundColor: active ? "#FFFFFF" : undefined,
                      "&:hover": {
                        color: "#037EF2",
                        backgroundColor: active ? "#FFFFFF" : undefined,
                        "& svg": {
                          fill: "#037EF2",
                        },
                      },
                    };
                  else {
                    return {
                      color: active
                        ? "rgba(255, 255, 255, 1)"
                        : "rgba(255, 255, 255, 0.5)",
                      backgroundColor: "#037EF2",
                      padding: "0px 0px 0px 80px",
                      "&:hover": {
                        color: "rgba(255, 255, 255, 1)",
                        backgroundColor: "#037EF2",
                      },
                    };
                  }
                },
              }}
            >
              <MenuItem
                href="/dashboard"
                icon={
                  <svg
                    width="19"
                    height="14"
                    viewBox="0 0 19 14"
                    fill="#FFFFFF"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.38468 0.0769043C4.61295 0.0769043 0.730838 3.95902 0.730838 8.73075C0.729719 10.0663 1.0412 11.3837 1.64036 12.5773C1.78747 12.8716 2.0869 13.0577 2.41488 13.0577H16.3537C16.6816 13.0577 16.982 12.8716 17.1291 12.5773C17.7281 11.3837 18.0397 10.0663 18.0385 8.73075C18.0385 3.95902 14.1564 0.0769043 9.38468 0.0769043ZM15.7972 11.3269H2.97218C2.63403 10.5032 2.46056 9.62117 2.46161 8.73075C2.46161 4.91354 5.56747 1.80767 9.38468 1.80767C13.202 1.80767 16.3078 4.91354 16.3078 8.73075C16.3088 9.62117 16.1354 10.5032 15.7972 11.3269Z" />

                    <path d="M8.46656 8.81257C8.28248 8.93296 8.13128 9.09731 8.02664 9.29083C7.921995 9.48421 7.867188 9.70079 7.867188 9.92071C7.867188 10.14064 7.921995 10.3571 8.02664 10.5506C8.13128 10.7441 8.28248 10.90841 8.46656 11.02887C8.61203 11.12452 8.77494 11.19064 8.94598 11.22329C9.117 11.25594 9.2928 11.25456 9.46329 11.21914C9.63378 11.18383 9.79563 11.11517 9.93955 11.01721C10.08348 10.91914 10.20667 10.79371 10.30205 10.6481L13.73068 5.42286C13.73345 5.418291 13.73494 5.413052 13.73494 5.407721C13.73494 5.402379 13.73345 5.397153 13.73068 5.392572C13.72664 5.386341 13.72029 5.381933 13.71302 5.380318C13.70575 5.378702 13.69814 5.379995 13.69179 5.383918L8.46656 8.81257Z" />
                  </svg>
                }
              >
                Dashboard
              </MenuItem>
              <SubMenu
                label="Opportunities"
                defaultOpen={pathname.startsWith("/opportunities")}
                active={pathname.startsWith("/opportunities")}
                icon={
                  <svg
                    width="21"
                    height="19"
                    viewBox="0 0 21 19"
                    fill="#FFFFFF"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19.8894 3.76664L14.0479 0.845963C13.9128 0.77828 13.7635 0.743042 13.6123 0.743042C13.461 0.743042 13.3119 0.77828 13.1766 0.845963L7.77145 3.54953L2.36528 0.845963C2.21682 0.771775 2.05185 0.736772 1.88606 0.744282C1.72025 0.751791 1.55913 0.801561 1.41798 0.888865C1.27683 0.97617 1.16034 1.09811 1.07958 1.2431C0.998819 1.3881 0.956465 1.55133 0.956543 1.7173V14.3735C0.956543 14.7425 1.16488 15.0794 1.49492 15.2449L7.33627 18.1656C7.47153 18.2332 7.62069 18.2685 7.77193 18.2685C7.92318 18.2685 8.07234 18.2332 8.2076 18.1656L13.6128 15.4619L19.0189 18.1645C19.167 18.2394 19.3319 18.275 19.4976 18.2676C19.6634 18.2603 19.8244 18.2103 19.9652 18.1227C20.2525 17.9445 20.4277 17.632 20.4277 17.2942V4.63797C20.4277 4.26899 20.2194 3.93214 19.8894 3.76664ZM8.745 5.23963L12.6392 3.29251V13.7718L8.745 15.7189V5.23963ZM2.90366 3.29251L6.79789 5.23963V15.7189L2.90366 13.7718V3.29251ZM18.4806 15.7189L14.5864 13.7718V3.29251L18.4806 5.23963V15.7189Z" />
                  </svg>
                }
              >
                <MenuItem
                  href="/opportunities"
                  active={pathname === "/opportunities"}
                >
                  Opportunities List
                </MenuItem>
                <MenuItem
                  href="/opportunities/assesments"
                  active={pathname === "/opportunities/assesments"}
                >
                  Assess the Application
                </MenuItem>
                <MenuItem
                  href="/opportunities/add"
                  active={pathname.startsWith("/opportunities/add")}
                >
                  Add Opportunity
                </MenuItem>
              </SubMenu>
              <SubMenu
                label="MX Surveys"
                defaultOpen={pathname.startsWith("/mx-surveys")}
                active={pathname.startsWith("/mx-surveys")}
                icon={
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="#FFFFFF"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M17.0577 7.40403H14.4615V1.34634C14.4615 1.11683 14.3704 0.896711 14.208 0.734422C14.0458 0.572134 13.8256 0.480957 13.5961 0.480957H1.48074C1.25123 0.480957 1.03111 0.572134 0.868822 0.734422C0.706533 0.896711 0.615356 1.11683 0.615356 1.34634V13.4617C0.615356 14.8931 1.78016 16.0579 3.21151 16.0579H15.3269C16.7582 16.0579 17.923 14.8931 17.923 13.4617V8.26942C17.923 8.03991 17.8319 7.81979 17.6695 7.6575C17.5073 7.49521 17.2872 7.40403 17.0577 7.40403ZM3.21151 14.3271C2.982 14.3271 2.76188 14.236 2.59959 14.0736C2.4373 13.9114 2.34613 13.6912 2.34613 13.4617V2.21173H12.7307V13.4617C12.7307 13.7655 12.7835 14.0571 12.8788 14.3271H3.21151ZM16.1923 13.4617C16.1923 13.6912 16.1011 13.9114 15.9388 14.0736C15.7766 14.236 15.5564 14.3271 15.3269 14.3271C15.0974 14.3271 14.8772 14.236 14.715 14.0736C14.5527 13.9114 14.4615 13.6912 14.4615 13.4617V9.1348H16.1923V13.4617Z" />
                    <path d="M4.0769043 2.942383H10.99998V4.67315H4.0769043V2.942383ZM4.0769043 6.40392H10.99998V8.13469H4.0769043V6.40392ZM8.40383 9.86546H10.99998V11.59623H8.40383V9.86546Z" />
                  </svg>
                }
              >
                <MenuItem
                  href="/mx-surveys"
                  active={pathname === "/mx-surveys"}
                >
                  Surveys List
                </MenuItem>
                <MenuItem
                  href="/mx-surveys/add"
                  active={pathname === "/mx-surveys/add"}
                >
                  Add Survey
                </MenuItem>
              </SubMenu>
              <MenuItem
                href="/complaints"
                icon={
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="#FFFFFF"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M17.0577 7.40403H14.4615V1.34634C14.4615 1.11683 14.3704 0.896711 14.208 0.734422C14.0458 0.572134 13.8256 0.480957 13.5961 0.480957H1.48074C1.25123 0.480957 1.03111 0.572134 0.868822 0.734422C0.706533 0.896711 0.615356 1.11683 0.615356 1.34634V13.4617C0.615356 14.8931 1.78016 16.0579 3.21151 16.0579H15.3269C16.7582 16.0579 17.923 14.8931 17.923 13.4617V8.26942C17.923 8.03991 17.8319 7.81979 17.6695 7.6575C17.5073 7.49521 17.2872 7.40403 17.0577 7.40403ZM3.21151 14.3271C2.982 14.3271 2.76188 14.236 2.59959 14.0736C2.4373 13.9114 2.34613 13.6912 2.34613 13.4617V2.21173H12.7307V13.4617C12.7307 13.7655 12.7835 14.0571 12.8788 14.3271H3.21151ZM16.1923 13.4617C16.1923 13.6912 16.1011 13.9114 15.9388 14.0736C15.7766 14.236 15.5564 14.3271 15.3269 14.3271C15.0974 14.3271 14.8772 14.236 14.715 14.0736C14.5527 13.9114 14.4615 13.6912 14.4615 13.4617V9.1348H16.1923V13.4617Z" />
                    <path d="M4.0769043 2.942383H10.99998V4.67315H4.0769043V2.942383ZM4.0769043 6.40392H10.99998V8.13469H4.0769043V6.40392ZM8.40383 9.86546H10.99998V11.59623H8.40383V9.86546Z" />
                  </svg>
                }
              >
                Complaints
              </MenuItem>
              <MenuItem
                icon={
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="#FFFFFF"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.6922 0.187778C9.41325 0.184381 8.14628 0.434657 6.96464 0.924118C5.78302 1.41358 4.71017 2.13252 3.80822 3.03933C1.96916 4.87838 0.956665 7.32396 0.956665 9.92336C0.956665 12.5227 1.96916 14.9683 3.80822 16.8074C5.64727 18.6464 8.09284 19.6589 10.6922 19.6589C13.2916 19.6589 15.7372 18.6464 17.5762 16.8074C19.4153 14.9683 20.4278 12.5227 20.4278 9.92336C20.4278 7.32396 19.4153 4.87838 17.5762 3.03933C16.6743 2.13252 15.6014 1.41358 14.4198 0.924118C13.2382 0.434657 11.9712 0.184381 10.6922 0.187778ZM16.1997 15.4308C14.7286 16.9018 12.7727 17.7118 10.6922 17.7118C8.61175 17.7118 6.65587 16.9018 5.18483 15.4308C3.71378 13.9597 2.90378 12.0039 2.90378 9.92336C2.90378 7.84287 3.71378 5.88699 5.18483 4.41594C6.40538 3.19085 8.00204 2.41121 9.71868 2.20207V9.92336C9.71868 10.1816 9.82126 10.4292 10.0038 10.6118C10.1864 10.7943 10.434 10.8969 10.6922 10.8969H18.4135C18.2043 12.6136 17.4247 14.2102 16.1997 15.4308ZM11.6658 8.9498V2.19526C13.3829 2.40757 14.9793 3.18947 16.1997 4.41594C17.4268 5.63584 18.2089 7.23243 18.4203 8.9498H11.6658Z" />
                  </svg>
                }
              >
                Analytics
              </MenuItem>
              {authcode !== "" && authcode !== null && (
                <MenuItem
                  href="/profile"
                  active={pathname.startsWith("/profile")}
                  icon={
                    <svg
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      fill="#FFFFFF"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9.69238 0.506104C8.70493 0.506104 7.73967 0.798914 6.91863 1.34751C6.09761 1.89611 5.45769 2.67585 5.07982 3.58811C4.70193 4.50039 4.60307 5.50425 4.79571 6.47272C4.98834 7.44119 5.46384 8.33078 6.16207 9.02901C6.8603 9.72724 7.74989 10.2027 8.71836 10.3954C9.68683 10.588 10.6907 10.4892 11.603 10.1113C12.5152 9.73339 13.295 9.09348 13.8436 8.27245C14.3922 7.45141 14.685 6.48616 14.685 5.49871C14.685 4.17459 14.159 2.90469 13.2227 1.9684C12.2864 1.03211 11.0165 0.506104 9.69238 0.506104ZM9.69238 8.49427C9.09991 8.49427 8.52075 8.31858 8.02814 7.98943C7.53551 7.66027 7.15156 7.19242 6.92484 6.64506C6.69811 6.09769 6.63879 5.49538 6.75438 4.91431C6.86995 4.33322 7.15525 3.79946 7.57419 3.38052C7.99313 2.96159 8.52689 2.6763 9.10798 2.56071C9.68905 2.44512 10.2914 2.50444 10.8387 2.73118C11.3861 2.9579 11.8539 3.34184 12.1831 3.83447C12.5122 4.32708 12.6879 4.90624 12.6879 5.49871C12.6879 6.29318 12.3723 7.05511 11.8106 7.6169C11.2488 8.17867 10.4868 8.49427 9.69238 8.49427ZM18.6791 19.478V18.4795C18.6791 16.6257 17.9427 14.8479 16.6319 13.5371C15.321 12.2262 13.5432 11.4898 11.6894 11.4898H7.69533C5.84157 11.4898 4.06372 12.2262 2.75291 13.5371C1.4421 14.8479 0.705688 16.6257 0.705688 18.4795V19.478H2.70273V18.4795C2.70273 17.1554 3.22873 15.8854 4.16503 14.9492C5.10132 14.0129 6.37122 13.4869 7.69533 13.4869H11.6894C13.0135 13.4869 14.2834 14.0129 15.2197 14.9492C16.1561 15.8854 16.682 17.1554 16.682 18.4795V19.478H18.6791Z" />
                    </svg>
                  }
                >
                  Profile
                </MenuItem>
              )}
            </Menu>
          </Sidebar>
        </div>
        <div style={{ flex: 2, backgroundColor: "#FAFBFE" }}>
          {props.children}
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Something wrong?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formTitle">
            <Form.Label>Complaint Subject</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={complaintSubj}
              onChange={handleComplaintSubjChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formTitle">
            <Form.Label>Complaint</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={complaint}
              onChange={handleComplaintChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              SubmitComplaint();
            }}
          >
            Submit Complaint
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  ) : (
    props.children
  );
}
export default MXSidebar;
