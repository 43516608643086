import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";
import { useQuery } from "@tanstack/react-query";
import { TailSpin } from "react-loader-spinner";

function RedirectProfile(props) {
  const api = process.env.REACT_APP_DATABASE_URI;
  const navigate = useNavigate();
  const [profileData, setProfileData, removeProfileData] = useSessionStorage(
    "profileData",
    ""
  );
  const [authcode, setAuthCode, removeAuthCode] = useSessionStorage(
    "authToken",
    ""
  );
  const getRedirectUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const return_url = params.get("return_url");
    return { return_url: return_url };
  };
  //Get the profile data for the profile picture in the nav bar
  const {
    isPending: isPendingProfile,
    isError: isErrorProfile,
    data: dataProfile,
    error: errorProfile,
    isSuccess: isSuccessProfile,
  } = useQuery({
    queryKey: ["profile"],
    retry: false,
    queryFn: () =>
      fetch(`${api}/users/self`, {
        mode: "cors",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${authcode}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok ", errorProfile);
        }
        return res.json();
      }),
  });
  useEffect(() => {
    if (isSuccessProfile) {
      setProfileData(dataProfile);
      props.callBack(true);
      navigate(getRedirectUrl().return_url);
    }
  });
  if (isPendingProfile) {
    return (
      <div className="redirect">
        <TailSpin
          visible={true}
          height="150"
          width="150"
          color="#037EF2"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  if (isErrorProfile) {
    console.error("Fetch error");
  }
}
export default RedirectProfile;
