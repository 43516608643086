import "./App.css";
import MXSidebar from "./Elements/MXSidebar";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./Views/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import OpportunitiesList from "./Views/Opportunities/Opportunitylist";
import { useEffect, useState } from "react";
import Profile from "./Views/Profile/Profile";
import RedirectHandler from "./Views/RedirectHandler";
import OpportunityDetail from "./Views/Opportunities/OpportunityDetails";
import SecureRoute from "./Elements/SecureRoute";
import PositionDetails from "./Views/Opportunities/PositionDetails";
import Dashboard from "./Views/Dashboard/Dashboard";
import SurveyList from "./Views/MX Surveys/SurveysList";
import ManageOpportunity from "./Views/Opportunities/ManageOpportunity";
import AddOportunity from "./Views/Opportunities/AddOpportunity";
import SurveyDetails from "./Views/MX Surveys/SurveyDetails";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import AddSurvey from "./Views/MX Surveys/AddSurvey";
import OpportunitiesAssesmentList from "./Views/Opportunities/OpportunityAssessmentlist";
import ComplaintList from "./Views/Complaints/ComplaintsList";
import RedirectProfile from "./Views/RedirectProfileGet";
require("typeface-poppins");
require("typeface-lato");
require("typeface-public-sans");
function App() {
  const [sideBarOn, setViewSideBar] = useState(true);
  return (
    <BrowserRouter>
      <ReactNotifications />
      <MXSidebar viewSideBar={sideBarOn}>
        <Routes>
          <Route
            path="/dashboard"
            element={<SecureRoute element={<Dashboard />} />}
          />
          <Route path="/login" element={<Login callBack={setViewSideBar} />} />
          <Route
            index
            path="/opportunities"
            element={<SecureRoute element={<OpportunitiesList />} />}
          />
          <Route
            index
            path="/opportunities/assesments"
            element={<SecureRoute element={<OpportunitiesAssesmentList />} />}
          />
          <Route
            path="/mx-surveys"
            element={<SecureRoute element={<SurveyList />} />}
          />
          <Route
            path="/complaints"
            element={<SecureRoute element={<ComplaintList />} />}
          />
          <Route
            path="/mx-surveys/add"
            element={<SecureRoute element={<AddSurvey />} />}
          />
          <Route
            path="/mx-surveys/:id"
            element={<SecureRoute element={<SurveyDetails />} />}
          />
          <Route
            path="/opportunities/manage/:id"
            element={<SecureRoute element={<ManageOpportunity />} />}
          />
          <Route
            path="/opportunities/add"
            element={<SecureRoute element={<AddOportunity />} />}
          />
          <Route
            path="/opportunities/:id"
            element={<SecureRoute element={<OpportunityDetail />} />}
          />
          <Route
            path="/opportunities/:id/:slotid"
            element={<SecureRoute element={<PositionDetails />} />}
          />
          <Route
            path="/redirect"
            element={<RedirectHandler callBack={setViewSideBar} />}
          />
          <Route
            path="/redirectProfile"
            element={<RedirectProfile callBack={setViewSideBar} />}
          />
          <Route
            path="/profile"
            element={<SecureRoute element={<Profile />} />}
          />
          <Route
            path="*"
            element={
              <SecureRoute element={<Login callBack={setViewSideBar} />} />
            }
          />
        </Routes>
      </MXSidebar>
    </BrowserRouter>
  );
}

export default App;
