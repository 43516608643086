import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Plus, XCircle } from "react-bootstrap-icons";
import { useSessionStorage } from "usehooks-ts";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

function AddOportunity() {
  const api = process.env.REACT_APP_DATABASE_URI;
  const navigate = useNavigate();
  const [authcode, setAuthCode, removeAuthCode] = useSessionStorage(
    "authToken",
    ""
  );

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    navigate("/opportunities");
  };
  const handleCloseAndAdd = () => {
    setShow(false);
  };

  //Add Opportunity POST
  const addOpp = useMutation({
    mutationFn: (body) => {
      return fetch(`${api}/opportunities/intial-positions`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
        body: JSON.stringify(body), // Convert body object to JSON string
      });
    },
    onSuccess: (data) => {
      console.log("Opportunity created successfully", data);

      setShow(true);
    },
    onError: (error) => {
      navigate("/redirect?code=refresh&return_url=/opportunities/add");
      return <h3>Error fetching</h3>;
    },
  });
  //Positions and Opportunity State Init
  const [positions, setPositions] = useState([]);
  const [opportunity, setOpportunity] = useState({
    is_live: true,
    title: "",
    scope: "local",
    description: "",
    no_positions: 0,
    deadline: "",
    start_date: "",
    end_date: "",
    assessment_form: "https://example.com/",
    application_booklet: "https://example.com/",
  });

  //Hande state changes for Opportunity
  const handleOpportunityChange = (event) => {
    const { name, value } = event.target;
    setOpportunity((prevOpportunity) => ({
      ...prevOpportunity,
      [name]: value,
    }));
  };

  //Add new position function
  const addNewPosition = () => {
    const newPosition = {
      posId: positions.length ? positions[positions.length - 1].posId + 1 : 1, // unique ID
      is_live: true,
      title: "New Position",
      required_position: "TM",
      job_description: "",
      requirements_description: "",
      benefits_description: "",
      no_slots: 0,
    };
    setPositions([...positions, newPosition]);
  };

  // Function to handle form updates
  const handleInputChange = (posId, field, value) => {
    setPositions((prevPositions) =>
      prevPositions.map((position) =>
        position.posId === posId ? { ...position, [field]: value } : position
      )
    );
  };

  //Function to handle removing Positions
  const removePosition = (posId) => {
    setPositions((prevPositions) =>
      prevPositions.filter((position) => position.posId !== posId)
    );
  };

  //Handle the submission of the Form
  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedOpportunity = {
      ...opportunity,
      no_positions: positions.length,
    };

    const body = { opportunity: updatedOpportunity, positions: positions };
    addOpp.mutate(body);
  };

  //Page Body
  return (
    <div className="addOpp">
      <h1 className="addTitle">Add Opportunity</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Enter title"
                value={opportunity.title}
                onChange={handleOpportunityChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formScope">
              <Form.Label>Scope</Form.Label>
              <Form.Control
                as="select"
                name="scope"
                value={opportunity.scope}
                onChange={handleOpportunityChange}
              >
                <option value="local">Local</option>
                <option value="national">National</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                placeholder="Enter description"
                value={opportunity.description}
                onChange={handleOpportunityChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formDeadline">
              <Form.Label>Application Deadline</Form.Label>
              <Form.Control
                type="date"
                name="deadline"
                value={opportunity.deadline}
                onChange={handleOpportunityChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formStartDate">
              <Form.Label>Experience Start Date</Form.Label>
              <Form.Control
                type="date"
                name="start_date"
                value={opportunity.start_date}
                onChange={handleOpportunityChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formEndDate">
              <Form.Label>Experience End Date</Form.Label>
              <Form.Control
                type="date"
                name="end_date"
                value={opportunity.end_date}
                onChange={handleOpportunityChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formAssessmentForm">
              <Form.Label>Assessment Form URL</Form.Label>
              <Form.Control
                type="url"
                name="assessment_form"
                placeholder="Enter assessment form URL"
                value={opportunity.assessment_form}
                onChange={handleOpportunityChange}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3" controlId="formApplicationBooklet">
              <Form.Label>Application Booklet URL</Form.Label>
              <Form.Control
                type="url"
                name="application_booklet"
                placeholder="Enter application booklet URL"
                value={opportunity.application_booklet}
                onChange={handleOpportunityChange}
              />
            </Form.Group>
          </Col>
          {positions.map((position) => (
            <div key={position.no_positions} className="addPosition">
              <Row>
                <h1 className="posTitle">{position.title}</h1>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId={`title-${position.posId}`}
                  >
                    <Form.Label>Position Title</Form.Label>
                    <Form.Control
                      type="text"
                      value={position.title}
                      onChange={(e) =>
                        handleInputChange(
                          position.posId,
                          "title",
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId={`requiredPos-${position.posId}`}
                  >
                    <Form.Label>Required Position</Form.Label>
                    <Form.Control
                      as="select"
                      value={position.required_position}
                      onChange={(e) =>
                        handleInputChange(
                          position.posId,
                          "required_position",
                          e.target.value
                        )
                      }
                    >
                      <option value="TM">TM</option>
                      <option value="TL">TL</option>
                      <option value="LCVP">LCVP</option>
                      <option value="LCP">LCP</option>
                      <option value="MCVP">MCVP</option>
                      <option value="MCP">MCP</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId={`JD-${position.posId}`}
                  >
                    <Form.Label>Job Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter job description"
                      value={position.job_description}
                      onChange={(e) =>
                        handleInputChange(
                          position.posId,
                          "job_description",
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId={`reqDesc-${position.posId}`}
                  >
                    <Form.Label>Requirements Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter requirements description"
                      value={position.requirements_description}
                      onChange={(e) =>
                        handleInputChange(
                          position.posId,
                          "requirements_description",
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId={`benefits_description-${position.posId}`}
                  >
                    <Form.Label>Benefits Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter benefits description"
                      value={position.benefits_description}
                      onChange={(e) =>
                        handleInputChange(
                          position.posId,
                          "benefits_description",
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId={`no_slots-${position.posId}`}
                  >
                    <Form.Label>Number of Slots</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      placeholder="Enter number of slots"
                      value={position.no_slots}
                      onChange={(e) =>
                        handleInputChange(
                          position.posId,
                          "no_slots",
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Button
                    className="removePosBtn"
                    variant="danger"
                    size="sm"
                    onClick={() => removePosition(position.posId)}
                  >
                    Remove Position
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Col md={12}>
            <Button className="addPosBtn" onClick={() => addNewPosition()}>
              <Plus color="#037EF2" />
              Add Position
            </Button>
          </Col>
          <Col md={12}>
            <Button size="lg" variant="primary" type="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Opportunity added successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseAndAdd}>
            Add another
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default AddOportunity;
