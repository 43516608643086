import OppInfo from "./OppInfo";
import "../../Assets/Scss/Opportunities.scss";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSessionStorage } from "usehooks-ts";
import { TailSpin } from "react-loader-spinner";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileInfoShort from "../Profile/ProfileInfoShort";

function ManageOpportunity() {
  const api = process.env.REACT_APP_DATABASE_URI;
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const oppId = pathname.split("/")[3];
  const [authcode, setAuthCode] = useSessionStorage("authToken", "");
  const queryClient = useQueryClient();
  const [disabledDropdowns, setDisabledDropdowns] = useState({}); // Track disabled states
  const [show, setShow] = useState(false);
  const [appId, setAppId] = useState(0);
  const [fetchDetail, setFetchDetail] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const {
    isPending,
    isError,
    data: opportunity,
  } = useQuery({
    queryKey: ["opportunity", oppId],
    retry: false,
    queryFn: () =>
      fetch(`${api}/opportunities/` + oppId, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      }),
  });

  const {
    isPending: isPendingAppDetail,
    isError: isErrorAppDetail,
    data: AppDetail,
  } = useQuery({
    queryKey: ["application", appId],
    retry: false,
    enabled: fetchDetail,
    queryFn: () =>
      fetch(`${api}/applications/` + appId, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      }),
  });

  const handleShowDetail = (id) => {
    console.log(id);
    setShow(true);
    setAppId(id);
    setFetchDetail(true);
  };

  const { isPending: isPendingPositions, data: positions } = useQuery({
    queryKey: ["opportunity_pos", oppId],
    retry: false,
    queryFn: () =>
      fetch(`${api}/opportunities/${oppId}/positions`, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      }),
  });

  const {
    isPending: isPendingPos,
    isSuccess,
    isError: isErrorPos,
    data: applications,
  } = useQuery({
    queryKey: ["applications", oppId],
    retry: false,
    queryFn: () =>
      fetch(`${api}/applications/opportunity/` + oppId, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
      }).then((res) => res.json()),
  });

  //Add Update position status
  const changeStatus = useMutation({
    mutationFn: (body) => {
      return fetch(`${api}/applications/` + body.id, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
        body: JSON.stringify(body.content), // Convert body object to JSON string
      });
    },
    onSuccess: (data) => {
      console.log("Application status updated", data);
      queryClient.invalidateQueries({ queryKey: ["applications", oppId] });
    },
  });

  //Helper function to get title based on Position ID
  const getTitleByPositionId = (positionId) => {
    const position = positions.find((pos) => pos.position_id === positionId);
    return position ? position.title : "Unknown Position"; // Return title or a fallback value
  };

  // Function to map between Database states and Select options
  const mapStatusToSelect = (status) => {
    switch (status.toLowerCase()) {
      case "applied":
        return "Pending";
      case "accepted":
        return "Approved";
      case "rejected":
        return "Rejected";
      case "finished":
        return "Finished";
      case "completed":
        return "Completed";
      default:
        return "Approved"; // For "approved", "realised", "finished", and "completed"
    }
  };

  // Function to map between Select states and Database options, the revers of the one above
  const mapSelectToStatus = (selectedStatus) => {
    switch (selectedStatus) {
      case "Pending":
        return "applied"; // Reverse mapping for "Pending"
      case "Approved":
        return "accepted"; // Reverse mapping for "Approved"
      case "Rejected":
        return "rejected"; // Reverse mapping for "Rejected"
      case "Finished":
        return "finished";
      case "Completed":
        return "completed";
      default:
        return "approved"; // Default case for all other states
    }
  };
  // Function to map select value to styling
  const getStatusStyle = (status) => {
    switch (status) {
      case "Pending":
        return {
          backgroundColor: "rgba(229, 182, 38, 0.15)",
          color: "#e5b626",
          border: "0.5px solid #e5b626",
          backgroundImage:
            "url('data:image/svg+xml;charset=UTF8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 10 6%27%3E%3Cpath fill=%27%23e5b626%27 d=%27M0 0L5 5L10 0z%27/%3E%3C/svg%3E')", // Correct downward arrow
          backgroundPosition: "right 10px center",
          backgroundRepeat: "no-repeat",
          paddingRight: "30px",
        };
      case "Approved":
      case "Finished":
      case "Completed":
        return {
          backgroundColor: "rgba(0, 193, 110, 0.15)",
          color: "#00c16e",
          border: "0.5px solid #00c16e",
          backgroundImage:
            "url('data:image/svg+xml;charset=UTF8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 10 6%27%3E%3Cpath fill=%2700c16e%27 d=%27M0 0L5 5L10 0z%27/%3E%3C/svg%3E')", // Correct downward arrow
          backgroundPosition: "right 10px center",
          backgroundRepeat: "no-repeat",
          paddingRight: "30px",
        };
      case "Rejected":
        return {
          backgroundColor: "rgba(248, 90, 64, 0.15)",
          color: "#f85a40",
          border: "0.5px solid #f85a40",
          backgroundImage:
            "url('data:image/svg+xml;charset=UTF8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 10 6%27%3E%3Cpath fill=%27%23f85a40%27 d=%27M0 0L5 5L10 0z%27/%3E%3C/svg%3E')", // Correct downward arrow
          backgroundPosition: "right 10px center",
          backgroundRepeat: "no-repeat",
          paddingRight: "30px",
        };
      default:
        return {};
    }
  };

  //Handle status change
  const handleStatus = (app, event) => {
    // Set the dropdown for this application as disabled
    setDisabledDropdowns((prev) => ({ ...prev, [app.application_id]: true }));

    let body = {
      content: {
        status: mapSelectToStatus(event.target.value),
        application_url: app.application_url,
      },
      id: app.application_id,
    };

    // Call mutation
    changeStatus.mutate(body, {
      onSettled: () => {
        // Optional: Re-enable after mutation completes
        setDisabledDropdowns((prev) => ({
          ...prev,
          [app.application_id]: false,
        }));
      },
    });
  };

  if (isPendingPos || isPending || isPendingPositions) {
    return (
      <TailSpin
        visible={true}
        height="150"
        width="150"
        color="#037EF2"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperClass="spinner"
      />
    );
  }

  if (isError || isErrorPos) {
    navigate(
      "/redirect?code=refresh&return_url=/opportunities/manage/" + oppId
    );
    return <h3>Error fetching</h3>;
  }

  if (isSuccess) {
    let sortedApplications = [];
    if (applications.detail === "No applications found") {
      // Set sortedApplications to an empty array if no applications are found
      sortedApplications = [];
    } else {
      // Sort applications by creation_date if there are applications
      sortedApplications = applications.sort(
        (a, b) => new Date(a.creation_date) - new Date(b.creation_date)
      );
    }

    return (
      <div className="manageOpps">
        <OppInfo data={opportunity} appCount={applications.length} />
        <Row>
          <Col lg={9}>
            <Table className="ApplicationsTableDetails">
              <thead>
                <tr>
                  <th>Identification</th>
                  <th>Full Name</th>
                  <th>Position Applied</th>
                  <th>Date of Application</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {applications.detail === "No applications found" ? (
                  <tr style={{ textAlign: "center" }}>
                    <td colSpan={4}>No Applications found</td>
                  </tr>
                ) : (
                  sortedApplications.map((ele) => {
                    return (
                      <tr
                        key={ele.application_id}
                        onClick={() => {
                          handleShowDetail(ele.application_id);
                        }}
                      >
                        <td>{ele.application_id}</td>
                        <td>{ele.applicant_name}</td>
                        <td>{getTitleByPositionId(ele.position_id)}</td>
                        <td>{dayjs(ele.creation_date).format("YYYY-MM-DD")}</td>
                        <td>
                          <Form.Select
                            className="status"
                            value={mapStatusToSelect(ele.status)}
                            style={getStatusStyle(
                              mapStatusToSelect(ele.status)
                            )}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(event) => handleStatus(ele, event)}
                            disabled={!!disabledDropdowns[ele.application_id]} // Disable based on state
                          >
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="Rejected">Rejected</option>
                            <option value="Finished">Finished</option>
                            <option value="Completed">Completed</option>
                          </Form.Select>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </Col>
          <Modal size="xl" show={show} onHide={handleClose}>
            {isPendingAppDetail ? (
              <div className="loader">
                <TailSpin
                  visible={true}
                  height="100"
                  width="100"
                  color="#037EF2"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <>
                <Modal.Header closeButton />
                <Modal.Body>
                  <ProfileInfoShort profileData={AppDetail.applicant} />
                  <h1>
                    Application Link:{" "}
                    <a href={AppDetail.application_url}>
                      {AppDetail.application_url}
                    </a>
                  </h1>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Modal>
          <Col className="status-stats">
            <div>
              <p>
                Approved{" "}
                <span style={{ color: "gray" }}>
                  (
                  {
                    sortedApplications.filter(
                      (app) => app.status === "accepted"
                    ).length
                  }{" "}
                  out of {sortedApplications.length})
                </span>
              </p>
              <ProgressBar
                completed={
                  sortedApplications.filter((app) => app.status === "accepted")
                    .length
                }
                bgColor="#00C16E"
                baseBgColor="rgba(0, 193, 110, 0.1)"
                isLabelVisible={false}
                maxCompleted={sortedApplications.length}
              />
            </div>
            <div>
              <p>
                Finished{" "}
                <span style={{ color: "gray" }}>
                  (
                  {
                    sortedApplications.filter(
                      (app) => app.status === "finished"
                    ).length
                  }{" "}
                  out of {sortedApplications.length})
                </span>
              </p>
              <ProgressBar
                completed={
                  sortedApplications.filter((app) => app.status === "finished")
                    .length
                }
                bgColor="#00C16E"
                baseBgColor="rgba(0, 193, 110, 0.1)"
                isLabelVisible={false}
                maxCompleted={sortedApplications.length}
              />
            </div>
            <div>
              <p>
                Completed{" "}
                <span style={{ color: "gray" }}>
                  (
                  {
                    sortedApplications.filter(
                      (app) => app.status === "completed"
                    ).length
                  }{" "}
                  out of {sortedApplications.length})
                </span>
              </p>
              <ProgressBar
                completed={
                  sortedApplications.filter((app) => app.status === "completed")
                    .length
                }
                bgColor="#00C16E"
                baseBgColor="rgba(0, 193, 110, 0.1)"
                isLabelVisible={false}
                maxCompleted={sortedApplications.length}
              />
            </div>
            <div>
              <p>
                Pending{" "}
                <span style={{ color: "gray" }}>
                  (
                  {
                    sortedApplications.filter((app) => app.status === "applied")
                      .length
                  }{" "}
                  out of {sortedApplications.length})
                </span>
              </p>
              <ProgressBar
                completed={
                  sortedApplications.filter((app) => app.status === "applied")
                    .length
                }
                bgColor="#E5B626"
                baseBgColor="rgba(229, 182, 38, 0.1)"
                isLabelVisible={false}
                maxCompleted={sortedApplications.length}
              />
            </div>
            <div>
              <p>
                Rejected{" "}
                <span style={{ color: "gray" }}>
                  (
                  {
                    sortedApplications.filter(
                      (app) => app.status === "rejected"
                    ).length
                  }{" "}
                  out of {sortedApplications.length})
                </span>
              </p>
              <ProgressBar
                completed={
                  sortedApplications.filter((app) => app.status === "rejected")
                    .length
                }
                bgColor="#F85A40"
                baseBgColor="rgba(248, 90, 64, 0.1)"
                isLabelVisible={false}
                maxCompleted={sortedApplications.length}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ManageOpportunity;
