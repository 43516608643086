import { useEffect, useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";

function ProfileContent(props) {
  const [desc, setDesc] = useState();
  const [nationality, setNationality] = useState();
  const [languages, setLanguages] = useState([]);
  useEffect(() => {
    setDesc(
      props.profileData.summary
        ? props.profileData.summary
        : "This user has not set his expa description"
    );
    setNationality(
      props.profileData.home_mc_full_name
        ? props.profileData.home_mc_full_name.split(" ").slice(2).join(" ")
        : ""
    );
    setLanguages(
      props.profileData.languages ? props.profileData.languages : []
    );
  }, [props.profileData]);
  return (
    <div className="profileDesc">
      <div className="profileParagraph">
        <p className="title">Description</p>
        <p>{desc}</p>
      </div>
      <div className="dataBadges profileParagraph">
        <Row>
          <Col className="infoData">
            <p className="title">
              Nationality <Badge>{nationality}</Badge>
            </p>
          </Col>
          <Col className="infoData">
            <p className="title">Skills {/* <Badge>Tunisia</Badge> */}</p>
          </Col>
          <Col className="infoData">
            <p className="title">
              Languages{" "}
              {languages.map((ele) => {
                return <Badge key={ele}>{ele}</Badge>;
              })}
            </p>
          </Col>
        </Row>
      </div>
      <div className="profileParagraph">
        <p className="title">Applications</p>
        <ul>
          <li>Borem ipsum dolor sit amet, consectetur adipiscing elit. </li>
          <li>Borem ipsum dolor sit amet, consectetur adipiscing elit. </li>
          <li>Borem ipsum dolor sit amet, consectetur adipiscing elit. </li>
        </ul>
      </div>
      <div className="profileParagraph">
        <p className="title">Past Experiences</p>
        <ul>
          <li>Borem ipsum dolor sit amet, consectetur adipiscing elit. </li>
          <li>Borem ipsum dolor sit amet, consectetur adipiscing elit. </li>
          <li>Borem ipsum dolor sit amet, consectetur adipiscing elit. </li>
        </ul>
      </div>
    </div>
  );
}
export default ProfileContent;
