import "../../Assets/Scss/Dashboard.scss";

function StatBlock(props) {
  return (
    <div>
      <p className="statValue">{props.stat.value}</p>
      <p className="statTitle"># of Members</p>
    </div>
  );
}
export default StatBlock;
