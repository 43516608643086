import { Badge, Col, Row } from "react-bootstrap";
import {
  Calendar3,
  Facebook,
  Instagram,
  PeopleFill,
  Whatsapp,
} from "react-bootstrap-icons";

function SurveyInfo(props) {
  return (
    <>
      <Row>
        <Col lg={3}>
          <p>
            <span className="survName">{props.data.oppName}</span>
          </p>
        </Col>
        <Col className="badge-container" lg={1}>
          {props.data.requirement === "VP+" ? (
            <Badge className="badge-vps">VPs +</Badge>
          ) : (
            ""
          )}
        </Col>
        <Col lg={2} className="applicantsNumber">
          <PeopleFill /> Over {props.data.appCount} applicants
        </Col>
        <Col lg={{ offset: "4" }} className="shareOn">
          <p>
            Share on: <Facebook /> <Instagram /> <Whatsapp />
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="survDuration" lg={5}>
          <Calendar3 size={32} /> From {props.data.app_duration}
        </Col>
        <Col className="appDate" lg={{ offset: "2", size: "2" }}>
          <p>Application Launch: {props.data.app_launch}</p>
          <svg
            width="1"
            height="60"
            viewBox="0 0 1 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="0.25"
              y1="-20"
              x2="0.249999"
              y2="60"
              stroke="#A098AE"
              strokeWidth="2"
            />
          </svg>
          <p className="deadline">Application Deadline: {props.data.app_ddl}</p>
        </Col>
      </Row>
    </>
  );
}
export default SurveyInfo;
