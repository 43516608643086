import { Navigate } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

function SecureRoute(props) {
  const [authcode, ,] = useSessionStorage("authToken", "");
  return (
    (authcode !== "" && authcode !== null && props.element) || (
      <Navigate to="/login" />
    )
  );
}
export default SecureRoute;
