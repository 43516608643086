import { Col, Row } from "react-bootstrap";
import "../../Assets/Scss/Dashboard.scss";
import StatBlock from "./StatBlock";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

function Dashboard() {
  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const stats = [
    { value: "30", title: "# of Members" },
    { value: "904%", title: "% ideal HR" },
    { value: "8.640", title: "LPS" },
    { value: "73", title: "avg LC size" },
    { value: "89%", title: "% retention rate" },
  ];
  const statLine = (
    <svg
      width="1"
      height="60"
      viewBox="0 0 1 34"
      fill="#E2E8F0"
      xmlns="http://www.w3.org/2000/svg"
      className="statLine"
    >
      <line
        x1="0.25"
        y1="-20"
        x2="0.249999"
        y2="60"
        stroke="#A098AE"
        strokeWidth="2"
      />
    </svg>
  );
  return (
    <div className="dashboard">
      <Row className="filter">
        <Col className="title">
          <p>Dashboard</p>
        </Col>
        {/* <Col className="statBlock">
          In
          <DropdownButton>
            <Dropdown.Item active href="#/action-1">
              Action
            </Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </DropdownButton>
        </Col> */}
      </Row>
      <div className="stats">
        <StatBlock stat={stats[0]} />
        {statLine}
        <StatBlock stat={stats[1]} />
        {statLine}
        <StatBlock stat={stats[2]} />
        {statLine}
        <StatBlock stat={stats[3]} />
        {statLine}
        <StatBlock stat={stats[4]} />
        {statLine}
        <StatBlock stat={stats[0]} />
        {statLine}
        <StatBlock stat={stats[1]} />
      </div>
      <div className="stats">
        <StatBlock stat={stats[0]} />
        {statLine}
        <StatBlock stat={stats[1]} />
        {statLine}
        <StatBlock stat={stats[2]} />
        {statLine}
        <StatBlock stat={stats[3]} />
        {statLine}
        <StatBlock stat={stats[4]} />
        {statLine}
        <StatBlock stat={stats[0]} />
        {statLine}
        <StatBlock stat={stats[1]} />
      </div>
      <div className="chartBlock">
        <div className="titleSection">
          <p className="chartTitle">MXP Funnel</p>
          <p className="chartSubTitle">Based on program duration</p>
        </div>
        <div className="chartSection" style={{ width: "100%", height: "40vh" }}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
          </LineChart>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
