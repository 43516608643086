import dayjs from "dayjs";
import { Badge, Col, Row } from "react-bootstrap";
import {
  Calendar3,
  Facebook,
  Instagram,
  PeopleFill,
  Whatsapp,
} from "react-bootstrap-icons";
var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);
function OppInfo(props) {
  return (
    <>
      <Row>
        <Col lg={3}>
          <p>
            <span className="oppName">
              {props.posTitle ? props.posTitle : props.data.title}
            </span>
          </p>
        </Col>
        {/* <Col className="badge-container" lg={1}>
          {props.data.requirement === "VP+" ? (
            <Badge className="badge-vps">VPs +</Badge>
          ) : (
            ""
          )}
        </Col> */}
        <Col lg={2} className="applicantsNumber">
          <PeopleFill /> {props.appCount} applicants
        </Col>
        <Col lg={{ offset: "4" }} className="shareOn">
          <p>
            Share on: <Facebook /> <Instagram /> <Whatsapp />
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="oppDuration" lg={5}>
          <Calendar3 size={32} /> From{" "}
          {dayjs(props.data.start_date).format("Do of MMMM YYYY")} <br />
          to {dayjs(props.data.end_date).format("Do of MMMM YYYY")}
        </Col>
        <Col className="appDate" lg={{ offset: "2", size: "2" }}>
          <p>
            Application Launch: <br />
            {dayjs(props.data.creation_date).format("YYYY-MM-DD")}
          </p>
          <svg
            width="1"
            height="60"
            viewBox="0 0 1 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="0.25"
              y1="-20"
              x2="0.249999"
              y2="60"
              stroke="#A098AE"
              strokeWidth="2"
            />
          </svg>
          <p className="deadline">
            Application Deadline: <br />
            {dayjs(props.data.deadline).format("YYYY-MM-DD")}
          </p>
        </Col>
      </Row>
    </>
  );
}
export default OppInfo;
