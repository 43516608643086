import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
function ProfileInfoShort(props) {
  useEffect(() => {}, [props.profileData]);
  return (
    <Row className="profInfo">
      <Col lg={2} md={4}>
        <img src={props.profileData.profile_photo} alt="profile" />
      </Col>
      <Col>
        <h1 className="name">{props.profileData.full_name}</h1>
        <p>
          <a href={`mailto:${props.profileData.aiesec_email}`}>
            {props.profileData.aiesec_email}
          </a>
        </p>
        <p>Local Comittee: {props.profileData.current_position_office}</p>
        <Row className="info"></Row>
      </Col>
      <Col lg={2}>
        <p className="currentPos">{props.profileData.current_position_role}</p>
      </Col>
    </Row>
  );
}
export default ProfileInfoShort;
