import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../../Assets/Scss/Profile.scss";
import ProfileInfo from "./ProfileInfo";
import ProfileContent from "./ProfileContent";
import ProfileTimeline from "./ProfileTimeline";
import { useSessionStorage } from "usehooks-ts";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

function Profile() {
  const api = process.env.REACT_APP_DATABASE_URI;
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({});
  const [authcode, setAuthCode, removeAuthCode] = useSessionStorage(
    "authToken",
    ""
  );
  const { isPending, isError, data, error } = useQuery({
    queryKey: ["profile"],
    retry: false,
    queryFn: () =>
      fetch(`${api}/users/self`, {
        mode: "cors",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${authcode}`,
        },
      }).then((res) => {
        if (!res.ok) {
          navigate("/redirect?code=refresh&return_url=/profile");
          throw new Error("Network response was not ok ", error);
        }
        return res.json();
      }),
  });
  if (isPending) {
    return (
      <TailSpin
        visible={true}
        height="150"
        width="150"
        color="#037EF2"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperClass="spinner"
      />
    );
  }

  /*   if (isError) {
    navigate("/redirect?code=refresh&return_url=/profile");
    return <h3>Error fetching</h3>;
  } */

  return (
    <div className="profilePage">
      <ProfileInfo profileData={data} />
      <div className="profileContent">
        <Tabs selectedTabClassName="tablist-selected">
          <TabList className="tabList">
            <Tab>General informations</Tab>
            <Tab>Timeline</Tab>
          </TabList>
          <TabPanel>
            <ProfileContent profileData={data} />
          </TabPanel>
          <TabPanel>
            <ProfileTimeline />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}
export default Profile;
