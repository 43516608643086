import { Badge, Col, Row } from "react-bootstrap";

function ProfileTimeline() {
  const applications = [
    {
      application_id: "01",
      application_title: "OCVP",
      stages: [
        { name: "Applied", date: "July 9, 2024", status: "applied" },
        {
          name: "Accepted by Host",
          date: "July 9, 2024",
          status: "accepted-host",
        },
        { name: "Accepted", date: "July 9, 2024", status: "accepted" },
        { name: "Approved", date: "July 9, 2024", status: "approved" },
        { name: "Realised", date: "July 9, 2024", status: "realised" },
        { name: "Finished", date: "July 9, 2024", status: "finished" },
      ],
    },
    {
      application_id: "02",
      application_title: "OCP",
      stages: [
        { name: "Applied", date: "July 9, 2024", status: "applied" },
        {
          name: "Accepted by Host",
          date: "July 9, 2024",
          status: "accepted-host",
        },
        { name: "Accepted", date: "July 9, 2024", status: "accepted" },
        { name: "Approved", date: "July 9, 2024", status: "approved" },
        { name: "Realised", date: "July 9, 2024", status: "realised" },
        { name: "Finished", date: "July 9, 2024", status: "finished" },
      ],
    },
  ];
  return (
    <div className="applicationTimeline">
      {applications.map((application, index) => (
        <div className="application-timeline" key={application.application_id}>
          <h2>
            Application: <span>{application.application_title}</span>
          </h2>
          <div className="timeline">
            {application.stages.map((stage, stageIndex) => (
              <div className="timeline-item" key={stageIndex}>
                <div className={`timeline-content ${stage.status}`}>
                  <p>{stage.name}</p>
                </div>
                <div className="timeline-dot"></div>
                <span className="timeline-date">{stage.date}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
export default ProfileTimeline;
