import { Badge, Table } from "react-bootstrap";
import "../../Assets/Scss/Opportunities.scss";
import OppInfo from "./OppInfo";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useSessionStorage } from "usehooks-ts";
import { TailSpin } from "react-loader-spinner";

function OpportunityDetail() {
  const api = process.env.REACT_APP_DATABASE_URI;
  const pathname = window.location.pathname;
  const oppId = pathname.split("/")[2];
  const [authcode, setAuthCode, removeAuthCode] = useSessionStorage(
    "authToken",
    ""
  );
  const navigate = useNavigate();
  const listcount = 6;
  const {
    isPending,
    isError,
    data: opportunity,
    error,
  } = useQuery({
    queryKey: ["opportunity", oppId],
    retry: false,
    queryFn: () =>
      fetch(`${api}/opportunities/` + oppId, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok ", error);
        }
        return res.json();
      }),
  });

  const {
    isPending: isPendingPos,
    isError: isErrorPos,
    data: positions,
    isSuccess,
    error: errorPos,
  } = useQuery({
    queryKey: ["positions", oppId],
    retry: false,
    queryFn: () =>
      fetch(`${api}/opportunities/` + oppId + "/positions/", {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authcode}`,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok ", errorPos);
        }
        return res.json();
      }),
  });

  const clickRow = (posId) => {
    navigate("/opportunities/" + oppId + "/" + posId);
  };

  if (isPending || isPendingPos) {
    return (
      <TailSpin
        visible={true}
        height="150"
        width="150"
        color="#037EF2"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperClass="spinner"
      />
    );
  }

  return (
    <div className="oppDetails">
      <OppInfo data={opportunity} />
      <Table className="OppsTableDetails">
        <thead>
          <tr>
            <th>Opportunity Name</th>
            <th>Required Position</th>
            <th>Number Of slots</th>
          </tr>
        </thead>
        <tbody>
          {positions.map((ele, index) => {
            if (ele.is_live)
              return (
                <tr
                  key={ele.position_id}
                  style={{ cursor: "pointer" }}
                  onClick={() => clickRow(ele.position_id)}
                >
                  <td>{ele.title}</td>
                  <td className="grayCol">
                    {(() => {
                      switch (ele.required_position) {
                        case "TM":
                          return <Badge className="badge-tm">TM</Badge>;
                        case "TL":
                          return <Badge className="badge-tl">TL</Badge>;
                        case "LCVP":
                          return <Badge className="badge-vps">LCVP</Badge>;
                        case "LCP":
                          return <Badge className="badge-lcp">LCP</Badge>;
                        case "MCVP":
                          return <Badge className="badge-vps">MCVP</Badge>;
                        case "MCP":
                          return <Badge className="badge-mcp">MCP</Badge>;
                        default:
                          return <Badge className="badge-other">Other</Badge>;
                      }
                    })()}
                  </td>
                  <td className="grayCol">{ele.no_slots}</td>
                </tr>
              );
          })}

          {[...Array(listcount)].map((obj, index) => {
            return (
              <tr key={index}>
                <td>&nbsp;</td>
                <td className="oppProv">&nbsp;</td>
                <td className="grayCol">&nbsp;</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
export default OpportunityDetail;
